// var menuItems = [];
let menu=[]
let menuItems= [
    {
        title: 'Heart Exchanges',
        route: 'heart-exchanges', 
        codename:'change_product'
    },
    {
        title: 'Matches',
        route: 'matches',
        codename:'change_product'
    },
    {
        title: 'Revealed',
        route: 'revealed',
        codename:'change_product'
    },
    {
        title: 'Payments',
        route: 'payments',
        codename:'change_product'
    },
    {
        title: 'Approved Payments',
        route: 'approved-payments',
        codename:'change_product'
    }
]

// var permissions = JSON.parse(localStorage.getItem('permissions'));
// permissions = permissions === null ? [] : permissions;
//
// items.forEach(item=>{
//     permissions.forEach(e => {
//         if (e === item['codename']) {
//             menuItems.push(item)
//         }
//     })
//
// })


if(menuItems.length!==0) {
    menu.push({
        title: 'Heart Management',
        icon: 'HeartIcon',
        children: menuItems
    })
}
export default menu;