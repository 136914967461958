var menu =
    [
        {
            title: 'Dashboard',
            route: 'dashboard', 
            icon: 'CodesandboxIcon',
        },
    ]

export default menu;
