// var menuItems = [];
let menu=[]
let menuItems= [
    {
        title: 'Users',
        route: 'users',
        codename:'add_product'
    },
    {
        title: 'Leads',
        route: 'leads',
        codename:'add_product'
    },
    {
        title: 'OTP Verified',
        route: 'otp-verified', 
        codename:'add_product'
    },
    {
        title: 'Review',
        route: 'review', 
        codename:'add_product'
    },
    {
        title: 'Edit ',
        route: 'edit', 
        codename:'add_product'
    },
    {
        title: 'Further Correction Due ',
        route: 'correction-due', 
        codename:'add_product'
    },
    {
        title: 'Pending Activation ',
        route: 'pending', 
        codename:'add_product'
    },
    {
        title: 'Active Profiles',
        route: 'active-profiles',
        codename:'add_product'
    },
    {
        title: 'Incompatible Profiles',
        route: 'incompatible-profiles',
        codename:'change_product'
    },
    {
        title: 'Deleted Profiles',
        route: 'deleted-profiles',
        codename:'change_product'
    },
    {
        title: 'Male Users',
        route: 'male-users',
        codename:'add_product'
    },
    {
        title: 'Female Users',
        route: 'female-users',
        codename:'add_product'
    },
    {
        title: 'Premium Users',
        route: 'premium-users',
        codename:'add_product'
    },
    {
        title: 'Regular Users',
        route: 'regular-users',
        codename:'add_product'
    },
]

// var permissions = JSON.parse(localStorage.getItem('permissions'));
// permissions = permissions === null ? [] : permissions;
//
// items.forEach(item=>{
//     permissions.forEach(e => {
//         if (e === item['codename']) {
//             menuItems.push(item)
//         }
//     })
//
// })


if(menuItems.length!==0) {
    menu.push({
        title: 'Subscribers',
        icon: 'UserIcon',
        children: menuItems
    })
}
export default menu;
